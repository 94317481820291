<template>
  <div class="window-owerlay">
    <div class="search-container">
      <input type="text" class="icon-rtl" value placeholder="Search" />
    </div>
    <div class="apps-container">
      <ul class="apps-folders-list">
        <li
          class="apps-folder-list-item"
          v-for="application in applications"
          :key="application.id"
          :data-id="application.id"
          @click="openFolder($event, application.id)"
        >
          <div class="item-contaiter" :data-id="application.id">
            <carousel
              v-if="
                application.id === $store.state.appId &&
                application.slides.length > 1 &&
                $store.state.appsOpen &&
                $store.state.isReady
              "
              :items-to-show="1"
            >
              <slide v-for="(slide, index) in application.slides" :key="index">
                <div
                  :data-id="application.id"
                  class="carousel__item apps-list"
                  :style="{
                    'grid-template-columns': `repeat(
                  ${slide.length >= 3 ? 3 : slide.length},
                  1fr
                )`,
                  }"
                >
                  <a
                    v-for="app in slide"
                    :key="app.appTitle"
                    :href="app.link"
                    class="app-item mini"
                    target="_blank"
                  >
                    <img
                      class="app-icon"
                      :src="app.imgUri"
                      :alt="app.appTitle"
                    />
                    <p class="app-title">{{ app.appTitle }}</p>
                  </a>
                </div>
              </slide>

              <template #addons>
                <!-- <navigation /> -->
                <pagination />
              </template>
            </carousel>
            <div
              v-else
              :data-id="application.id"
              class="apps-list"
              :style="{
                'grid-template-columns': `repeat(
                  ${
                    application.slides[0].length >= 3
                      ? 3
                      : application.slides[0].length
                  },
                  1fr
                )`,
              }"
            >
              <a
                v-for="app in application.slides[0]"
                :key="app.appTitle"
                :href="app.link"
                class="app-item mini"
                target="_blank"
              >
                <img class="app-icon" :src="app.imgUri" :alt="app.appTitle" />
                <p class="app-title">{{ app.appTitle }}</p>
              </a>
            </div>
            <p class="infolder-title">{{ application.title }}</p>
          </div>
          <p class="outfolder-title">{{ application.title }}</p>
        </li>
      </ul>
    </div>
    <!-- <transition>
      <div class="modal"></div>
    </transition> -->
  </div>
</template>

<script>
// @ is an alias to /src
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default {
  name: "Apps",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  data() {
    return {
      currentElement: null,
      applications: [
        {
          id: 1,
          title: "課堂工具",
          slides: [
            [
              {
                appTitle: "Google Classroom",
                imgUri: require("./../assets/clsssroom.webp"),
                link: "https://play.google.com/store/apps/details?id=com.google.android.apps.classroom",
              },
              {
                appTitle: "Jamboard",
                imgUri: require("./../assets/Jamboard.webp"),
                link: "https://play.google.com/store/apps/details?id=com.google.android.apps.jam",
              },
              {
                appTitle: "Kahoot",
                imgUri: require("./../assets/kahootwebp.webp"),
                link: "https://play.google.com/store/apps/details?id=no.mobitroll.kahoot.android",
              },
              {
                appTitle: "Notion",
                imgUri: require("./../assets/notion.webp"),
                link: "https://play.google.com/store/apps/details?id=notion.id",
              },
              {
                appTitle: "Quizlet",
                imgUri: require("./../assets/Quizlet.webp"),
                link: "https://play.google.com/store/apps/details?id=com.quizlet.quizletandroid",
              },
              {
                appTitle: "Canva",
                imgUri: require("./../assets/Canva.webp"),
                link: "https://play.google.com/store/apps/details?id=com.canva.editor",
              },
            ],
          ],
        },
        {
          id: 2,
          title: "視訊軟體",
          slides: [
            [
              {
                appTitle: "Google Meet",
                imgUri: require("./../assets/Google_Meet.webp"),
                link: "https://play.google.com/store/apps/details?id=com.google.android.apps.meetings",
              },
              {
                appTitle: "Microsoft Teams",
                imgUri: require("./../assets/Microsoft_Teams.webp"),
                link: "https://play.google.com/store/apps/details?id=com.microsoft.teams",
              },
            ],
          ],
        },
        {
          id: 3,
          title: "教科書專區",
          slides: [
            [
              {
                appTitle: "南一(OneBook)",
                imgUri: require("./../assets/OneBook.webp"),
                link: "https://play.google.com/store/apps/details?id=com.nani.onebook",
              },
              {
                appTitle: "康軒",
                imgUri: require("./../assets/康軒電子書KNSH.webp"),
                link: "https://play.google.com/store/apps/details?id=tw.com.knsh.G4931E9FBE7164520B47023FA54E95914",
              },
              {
                appTitle: "翰林",
                imgUri: require("./../assets/翰林行動大師EBYK.webp"),
                link: "https://play.google.com/store/apps/details?id=air.com.hanlin.h5ebook",
              },
              {
                appTitle: "龍騰",
                imgUri: require("./../assets/龍騰電子書SYPIRBYK.webp"),
                link: "https://play.google.com/store/apps/details?id=com.lungteng.android.superbook",
              },
              {
                appTitle: "三民東大",
                imgUri: require("./../assets/Learn_LOGO.png"),
                link: "https://elearning.sanmin.com.tw/",
              },
              {
                appTitle: "泰宇",
                imgUri: require("./../assets/book.jpg"),
                link: "https://www.taiyucoo.com.tw/",
              },
            ],
            [
              {
                appTitle: "全華",
                imgUri: require("./../assets/newChwa.png"),
                link: "http://www.chwa.com.tw/newChwa/newIndex.html",
              },
            ],
          ],
        },
        {
          id: 4,
          title: "數位閱讀",
          slides: [
            [
              {
                appTitle: "國圖到你家",
                imgUri: require("./../assets/國圖到你家NCLLIBRARY.webp"),
                link: "https://play.google.com/store/apps/details?id=tw.org.itri.nclibrary",
              },
              {
                appTitle: "康軒",
                imgUri: require("./../assets/NCLReader.webp"),
                link: "https://play.google.com/store/apps/details?id=tw.edu.ncl.reader",
              },
            ],
          ],
        },
        {
          id: 5,
          title: "數位學習平臺",
          slides: [
            [
              {
                appTitle: "PaGamO",
                imgUri: require("./../assets/PaGamO.webp"),
                link: "https://play.google.com/store/apps/details?id=com.boniotw.global.pagamo",
              },
              {
                appTitle: "教育部因材網",
                imgUri: require("./../assets/adledu.png"),
                link: "https://adl.edu.tw/HomePage/home/",
              },
              {
                appTitle: "均一",
                imgUri: require("./../assets/junyiacademy.png"),
                link: "https://www.junyiacademy.org/",
              },
              {
                appTitle: "學習吧",
                imgUri: require("./../assets/learnmode.png"),
                link: "https://www.learnmode.net/home/",
              },
            ],
          ],
        },
        {
          id: 6,
          title: "辦公室軟體",
          slides: [
            [
              {
                appTitle: "Google 文件",
                imgUri: require("./../assets/2991108.png"),
                link: "https://docs.google.com/document",
              },
              {
                appTitle: "Google 簡報",
                imgUri: require("./../assets/unnamed.png"),
                link: "https://docs.google.com/presentation",
              },
              {
                appTitle: "Google 畫布",
                imgUri: require("./../assets/canvas_48.png"),
                link: "https://canvas.apps.chrome",
              },
            ],
          ],
        },
      ],
    };
  },

  methods: {
    openFolder(e, appId) {
      this.$emit("emit-folder", e, appId);
      // if (
      //   this.currentElement !== null &&
      //   this.currentElement.classList.contains("open")
      // ) {
      //   this.currentElement.classList.remove("open");
      // }
      // e.currentTarget.classList.add("open");
      // this.currentElement = e.currentTarget;
    },
  },
};
</script>
<style scoped>
p {
  line-height: 1.3;
}
.apps-container {
  width: 80%;
  max-width: 600px;
  background-color: rgba(192, 192, 192, 0.3);
  border-radius: 30px;
  margin: 0 auto;
  margin-top: 30px;
  padding: 30px;
  position: relative;
}
.hide {
  display: none;
}
.outfolder-title {
  margin-top: 55px;
}
.apps-folders-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
@media screen and (min-width: 420px) {
  .apps-folders-list {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
}
@media screen and (min-width: 600px) {
  .apps-folders-list {
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
  }
}
.apps-folder-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.item-contaiter {
  background-color: rgba(250, 250, 250, 0.8);
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  position: absolute;
  transition: all 0.2s ease;
}

.app-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  justify-content: space-between;
}
.app-icon {
  display: block;
  border-radius: 5px;
}
.app-title {
  display: none;
  font-size: 10px;
  color: #2c3e50;
  margin-top: 5px;
}
.apps-folder-list-item.open {
  cursor: unset;
}

.apps-folder-list-item.open .item-contaiter {
  width: 250px;
  height: 260px;
  border-radius: 20px;

  top: -60px;
  padding: 10px;
  background-color: #fff;
  z-index: 10;

  justify-content: space-between;

  -webkit-box-shadow: 1px 0px 11px 5px rgba(0, 0, 0, 0.32);
  box-shadow: 1px 0px 11px 5px rgba(0, 0, 0, 0.32);
}

.apps-list {
  display: grid;

  gap: 5px;
}
.carousel__pagination {
  display: none;
}
.apps-folder-list-item.open .carousel__pagination {
  display: flex;
}
.apps-folder-list-item.open .apps-list {
  grid-template-columns: repeat(3, 1fr) !important;
  gap: 15px;
}

.apps-folder-list-item.open .app-item {
  pointer-events: unset;
}
.infolder-title {
  display: none;
  pointer-events: none;
}
.app-title {
  display: none;
}

.apps-folder-list-item.open .infolder-title {
  display: block;
}
.apps-folder-list-item.open .app-title {
  display: block;
}

.window-owerlay {
  margin-top: 40px;
}

.icon-rtl {
  width: 80%;
  max-width: 500px;
  /* background: dodgerblue; */
  border: solid 1px rgba(192, 192, 192);
  color: rgba(192, 192, 192);
  border-radius: 20px;
  padding-left: 15px;

  padding-right: 25px;
  background: url("https://static.thenounproject.com/png/101791-200.png")
    no-repeat;
  background-size: 20px;
  background-position: 97% 5px;
}
.carousel__slide {
  display: unset;
  justify-content: unset;
  align-items: unset;
}

/* .app-item.mini {
} */
</style>
