async function fullScreen(element) {
  console.log(element, element.requestFullscreen);
  if (element.requestFullscreen) {
    await element.requestFullscreen();
  } else if (element.webkitrequestFullscreen) {
    await element.webkitRequestFullscreen();
  } else if (element.mozRequestFullscreen) {
    await element.mozRequestFullScreen();
  }
}
export { fullScreen };
