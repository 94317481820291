<template>
  <div>
    <section class="pricing-section centred">
      <div class="container">
        <div class="sec-title center">
          <h2>數位內容專區</h2>
          <p>
            <img src="images/globe.png" alt="WEB" />網站
            <img src="images/apps.png" alt="APP" />App
          </p>
        </div>
        <div class="tabs-box">
          <div class="tabs-content">
            <div class="tab active-tab" id="tab-1">
              <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-12 pricing-column">
                  <div class="pricing-block-one">
                    <div class="pricing-table">
                      <figure class="image">
                        <img src="images/icon-1-01.png" alt="" />
                      </figure>
                      <div class="table-header">
                        <h3 class="price">國小英語文</h3>
                      </div>
                      <div class="table-content">
                        <ul>
                          <li>
                            <img
                              src="images/icons/image002.png"
                              width="25px"
                              alt="logo"
                            /><a
                              href="https://pbskids.org/games"
                              target="_blank"
                            >
                              PBS games for kids </a
                            ><img src="images/globe.png" alt="WEB" />
                            <img src="images/apps.png" alt="APP" />
                          </li>
                          <li>
                            <a href="http://www.eslkidslab.com/" target="_blank"
                              >ESL Kids Lab </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://kids.wordsmyth.net/we/"
                              target="_blank"
                              >Children's Dictionary</a
                            ><img src="images/globe.png" alt="" />
                            <img src="images/apps.png" alt="APP" />
                          </li>
                          <li>
                            <a href="https://www.opdome.com/" target="_blank"
                              >Online Picture Dictionary </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://www.teachyourmonster.org/"
                              target="_blank"
                              >Teach Your Monster To Read </a
                            ><img src="images/globe.png" alt="" />
                            <img src="images/apps.png" alt="APP" />
                          </li>
                          <li>
                            <a href="https://supersimple.com/" target="_blank"
                              >Super Simple </a
                            ><img src="images/globe.png" alt="" />
                            <img src="images/apps.png" alt="APP" />
                          </li>
                          <li>
                            <a
                              href="https://bogglesworldesl.com/"
                              target="_blank"
                              >Lantern Fish </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://www.greatschools.org/"
                              target="_blank"
                              >Great!SCHOOLS </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://www.littlefox.com/en"
                              target="_blank"
                              >Little Fox </a
                            ><img src="images/globe.png" alt="" />
                            <img src="images/apps.png" alt="APP" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 pricing-column">
                  <div class="pricing-block-one">
                    <div class="pricing-table">
                      <figure class="image">
                        <img src="images/icon-1-01.png" alt="" />
                      </figure>
                      <div class="table-header">
                        <h3 class="price">國中英語文</h3>
                      </div>
                      <div class="table-content">
                        <ul>
                          <li>
                            <a href="https://www.eslcafe.com/" target="_blank"
                              >Dave's ESL Cafe - Grammar Lessons </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://www.talkenglish.com"
                              target="_blank"
                              >TALKENGLISH </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a href="https://www.cmlibrary.org/" target="_blank"
                              >Charlotte Mecklenburg Library </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a href="http://www.wordhippo.com" target="_blank"
                              >Word Hippo </a
                            ><img src="images/globe.png" alt="" />
                            <img src="images/apps.png" alt="APP" />
                          </li>
                          <li>
                            <a
                              href="https://earth.google.com/web/@17.90693717,-12.41937117,-34606.55899502a,57359668.97d,35y,0.00004064h,18.19296234t,0r/data=CjwSOhIgYmU3N2ZmYzU0MTc1MTFlOGFlOGZkMzdkYTU5MmE0MmEiFnNwbC14LXgteC1zcGxhc2hzY3JlZW4"
                              target="_blank"
                              >Carmen Sandiego 神偷卡門 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://www.bbc.co.uk/newsround"
                              target="_blank"
                              >CBBC Newsround </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="http://www.visualdictionaryonline.com/"
                              target="_blank"
                              >Visual Dictionary Online </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://virtualvacation.us/guess"
                              target="_blank"
                              >City Guesser 城市猜一猜 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a href="https://reading.ecb.org/" target="_blank"
                              >Into the book </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 pricing-column">
                  <div class="pricing-block-one">
                    <div class="pricing-table">
                      <figure class="image">
                        <img src="images/icon-1-01.png" alt="" />
                      </figure>
                      <div class="table-header">
                        <h3 class="price">高中英語文</h3>
                      </div>
                      <div class="table-content">
                        <ul>
                          <li>
                            <a
                              href="https://www.talkenglish.com"
                              target="_blank"
                              >TALKENGLISH </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://www.bbc.co.uk/learningenglish/gothedistance/academicwriting"
                              target="_blank"
                              >BBC Learning English Academic Writing </a
                            ><img src="images/globe.png" alt="" />
                            <img src="images/apps.png" alt="APP" />
                          </li>
                          <li>
                            <a href="https://www.vocabulary.com" target="_blank"
                              >Vocabulary.com </a
                            ><img src="images/globe.png" alt="" />
                            <img src="images/apps.png" alt="APP" />
                          </li>
                          <li>
                            <a
                              href="https://www.perfect-english-grammar.com/"
                              target="_blank"
                              >Perfect English Grammar </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a href="https://www.blooket.com" target="_blank"
                              >Blooket </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://www.oxfordonlineenglish.com/free-spoken-english-lessons"
                              target="_blank"
                              >Oxford Online English </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://www.bbc.co.uk/learningenglish/english/basic-vocabulary"
                              target="_blank"
                              >BBC Learning English Basic Vocabulary </a
                            ><img src="images/globe.png" alt="" />
                            <img src="images/apps.png" alt="APP" />
                          </li>
                          <li>
                            <a
                              href="https://learnenglish.britishcouncil.org/english-grammar-reference"
                              target="_blank"
                              >British Council LearnEnglish English Grammar
                              Reference </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a href="https://www.engvid.com/" target="_blank"
                              >engVid </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 pricing-column">
                  <div class="pricing-block-one">
                    <div class="pricing-table">
                      <figure class="image">
                        <img src="images/icon-1-01.png" alt="" />
                      </figure>
                      <div class="table-header">
                        <h3 class="price">不分階段英語文</h3>
                      </div>
                      <div class="table-content">
                        <ul>
                          <li>
                            <a
                              href="https://www.coolenglish.edu.tw/"
                              target="_blank"
                              >CoolEnglish </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://www.khanacademy.org/"
                              target="_blank"
                              >khan academy </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a href="https://www.edx.org/" target="_blank"
                              >edx</a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a href="https://www.coursera.org/" target="_blank"
                              >Coursera</a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a href="https://ttsdemo.com/" target="_blank"
                              >Text-to-Speech </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a href="https://www.grammarly.com" target="_blank"
                              >Grammarly </a
                            ><img src="images/globe.png" alt="" />
                            <img src="images/apps.png" alt="APP" />
                          </li>
                          <li>
                            <a href="https://storybird.com/" target="_blank"
                              >Storybird </a
                            ><img src="images/globe.png" alt="" />
                            <img src="images/apps.png" alt="APP" />
                          </li>
                          <li>
                            <a
                              href="https://easyworldofenglish.com/"
                              target="_blank"
                              >Easy World Of English </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://learnenglish.britishcouncil.org/"
                              target="_blank"
                              >British Council LearnEnglish </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- -section end -->

    <!-- -section -->
    <section class="pricing-section centred">
      <div class="container">
        <div class="sec-title center">
          <h2>本土語言專區</h2>
          <p>
            <img src="images/globe.png" alt="WEB" />網站
            <img src="images/apps.png" alt="APP" />App
          </p>
        </div>
        <div class="tabs-box">
          <div class="tabs-content">
            <div class="tab active-tab" id="tab-1">
              <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 pricing-column">
                  <div class="pricing-block-one">
                    <div class="pricing-table">
                      <figure class="image">
                        <img src="images/icon-6-01.png" alt="" />
                      </figure>
                      <div class="table-header">
                        <h3 class="price">閩南語文</h3>
                      </div>
                      <div class="table-content">
                        <ul>
                          <li>
                            <a
                              href="https://taiwanstory.naer.edu.tw/"
                              target="_blank"
                              >臺灣小故事 </a
                            ><img src="images/globe.png" alt="WEBSITE" />
                          </li>
                          <li>
                            <a href="https://www.tgb.org.tw/" target="_blank"
                              >李江却台語文教基金會—台語繪本 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <img
                              src="images/icons/image011.png"
                              width="25px"
                              alt="logo"
                            /><a
                              href="https://pbskids.org/games"
                              target="_blank"
                            ></a>
                            <a
                              href="https://thauphang.blogspot.com/"
                              target="_blank"
                              >粉紅色小屋 Pink Phang </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <img
                              src="images/icons/image012.png"
                              width="25px"
                              alt="logo"
                            /><a
                              href="https://pbskids.org/games"
                              target="_blank"
                            ></a>
                            <a
                              href="https://twbangga.moe.edu.tw/index"
                              target="_blank"
                              >教育部閩南語動畫網 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://tailo.moe.edu.tw/mkwriting/index.php"
                              target="_blank"
                              >閩客語寫字練習簿 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://www.facebook.com/CTSHSTT/"
                              target="_blank"
                              >華視潮台 </a
                            ><img src="images/globe.png" alt="" />
                            <img src="images/apps.png" alt="APP" />
                          </li>
                          <li>
                            <a href="https://livestudy.tw/iholo" target="_blank"
                              >閩南語文直播共學數位教材—國小朗讀稿 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a href="https://taigi.pts.org.tw/" target="_blank"
                              >公視台語台 </a
                            ><img src="images/globe.png" alt="" />
                            <img src="images/apps.png" alt="APP" />
                          </li>
                          <li>
                            <a
                              href="https://www.facebook.com/groups/434167333333483"
                              target="_blank"
                              >臺灣話資料交流所 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 pricing-column">
                  <div class="pricing-block-one">
                    <div class="pricing-table">
                      <figure class="image">
                        <img src="images/icon-6-01.png" alt="" />
                      </figure>
                      <div class="table-header">
                        <h3 class="price">閩東語文</h3>
                      </div>
                      <div class="table-content">
                        <ul>
                          <li>
                            <img
                              src="images/icons/image008.png"
                              width="25px"
                              alt="logo"
                            /><a
                              href="https://pbskids.org/games"
                              target="_blank"
                            ></a>
                            <a
                              href="https://mmintung.iformosa.com.tw/"
                              target="_blank"
                              >新編國小一年級教材 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <img
                              src="images/icons/image008.png"
                              width="25px"
                              alt="logo"
                            /><a
                              href="http://www.matsudialect.org/nicetaste/000.html"
                              target="_blank"
                              >幼兒園中班閩東語教材 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <img
                              src="images/icons/image008.png"
                              width="25px"
                              alt="logo"
                            /><a
                              href="http://www.matsudialect.org/csmt/000.html"
                              target="_blank"
                              >幼兒園大班閩東語教材 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <img
                              src="images/icons/image008.png"
                              width="25px"
                              alt="logo"
                            /><a
                              href="http://www.matsudialect.org/pocket/index.htm#"
                              target="_blank"
                              >齊講馬祖話120句口袋書 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <img
                              src="images/icons/image009.png"
                              width="25px"
                              alt="logo"
                            /><a
                              href="http://www.matsudialect.org/pocket/index.htm
https://www.youtube.com/playlist?list=PLmj3WGdEASYP2jZa03t_rM5yCPqG2z75H"
                              target="_blank"
                              >1-6年級福州語教材(90年版)共68課 介壽國中小 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <img
                              src="images/icons/image008.png"
                              width="25px"
                              alt="logo"
                            /><a href="http://fc-matsu.com/" target="_blank"
                              >馬祖閩東語本字檢索系統 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <img
                              src="images/icons/image008.png"
                              width="25px"
                              alt="logo"
                            /><a
                              href="https://create.kahoot.it/details/9c72d3c4-d040-457f-ad7b-1598615143ec"
                              target="_blank"
                              >馬祖閩東語文檢測試題60題 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <img
                              src="images/icons/image008.png"
                              width="25px"
                              alt="logo"
                            /><a
                              href="http://www.matsudialect.org/1000/index.html"
                              target="_blank"
                              >馬祖閩東(福州話)日常生活常用詞彙 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <img
                              src="images/icons/image008.png"
                              width="25px"
                              alt="logo"
                            /><a
                              href="http://www.matsudialect.org/NewWeb/2020/StudySource.asp"
                              target="_blank"
                              >文章 小論文 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 pricing-column">
                  <div class="pricing-block-one">
                    <div class="pricing-table">
                      <figure class="image">
                        <img src="images/icon-6-01.png" alt="" />
                      </figure>
                      <div class="table-header">
                        <h3 class="price">客家語文</h3>
                      </div>
                      <div class="table-content">
                        <ul>
                          <li>
                            <a href="https://kids.hakka.gov.tw/" target="_blank"
                              >客話學習網 </a
                            ><img src="images/globe.png" alt="" />
                            <img src="images/apps.png" alt="APP" />
                          </li>
                          <li>
                            <a
                              href="https://mhotai.kl.edu.tw/82"
                              target="_blank"
                              >客家語(四縣腔)教學動畫影片 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://mhotai.kl.edu.tw/84"
                              target="_blank"
                              >客家語(海陸腔)教學動畫影片 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://broadcasting.hakka.gov.tw/"
                              target="_blank"
                              >好客ING/卡通節目</a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://elearning.hakka.gov.tw/ver2015/hundred/default/hundred.aspx"
                              target="_blank"
                              >實用生活客語 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://www.hakka-cuisine.ntpc.gov.tw/bin/home.php"
                              target="_blank"
                              >新北市客家觀光美食館</a
                            ><img src="images/globe.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 pricing-column">
                  <div class="pricing-block-one">
                    <div class="pricing-table">
                      <figure class="image">
                        <img src="images/icon-4-01.png" alt="" />
                      </figure>
                      <div class="table-header">
                        <h3 class="price">原住民族語文</h3>
                      </div>
                      <div class="table-content">
                        <ul>
                          <li>
                            <a
                              href="http://web.klokah.tw/extension/ab_practice/"
                              target="_blank"
                              >族語Ｅ樂園-四套教材/字母篇 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="http://web.klokah.tw/extension/song_practice"
                              target="_blank"
                              >族語Ｅ樂園-四套教材/歌謠篇 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <img
                              src="images/icons/image006.png"
                              width="25px"
                              alt="logo"
                            /><a
                              href="https://pbskids.org/games"
                              target="_blank"
                            ></a
                            ><a
                              href="https://www.museum.org.tw/exhibitions.php"
                              target="_blank"
                              >順益博物館-展示/常設展 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="http://web.klokah.tw/extension/ps_practice/"
                              target="_blank"
                              >族語Ｅ樂園-四套教材/圖畫故事篇 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="http://web.klokah.tw/essay/"
                              target="_blank"
                              >族語Ｅ樂園-補充教材/族語短文 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 pricing-column">
                  <div class="pricing-block-one">
                    <div class="pricing-table">
                      <figure class="image">
                        <img src="images/icon-4-01.png" alt="" />
                      </figure>
                      <div class="table-header">
                        <h3 class="price">新住民語文專區</h3>
                      </div>
                      <div class="table-content">
                        <ul>
                          <li>
                            <a href="https://mkm.k12ea.gov.tw/" target="_blank"
                              >新住民子女教育資訊網 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 pricing-column">
                  <div class="pricing-block-one">
                    <div class="pricing-table">
                      <figure class="image">
                        <img src="images/icon-4-01.png" alt="" />
                      </figure>
                      <div class="table-header">
                        <h3 class="price">臺灣手語專區</h3>
                      </div>
                      <div class="table-content">
                        <ul>
                          <li>
                            <a
                              href="https://signlanguage.ccu.edu.tw/placenames.php"
                              target="_blank"
                              >中正大學臺灣手語地名電子資料庫 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://signlanguage.ccu.edu.tw/familynames.php"
                              target="_blank"
                              >中正大學臺灣手語姓氏電子資料庫 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://special.moe.gov.tw/signlanguage"
                              target="_blank"
                              >常用手語辭典 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="http://lngproc.ccu.edu.tw/TSL/"
                              target="_blank"
                              >中正大學臺灣手語線上辭典 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- -section end -->

    <!-- -section  -->
    <section class="pricing-section centred">
      <div
        class="image-layer"
        style="background-image: url(images/icons/testimonial-bg.png)"
      ></div>
      <div class="container">
        <div class="sec-title center">
          <h2>學習專區</h2>
          <p>
            <img src="images/globe.png" alt="WEB" />網站
            <img src="images/apps.png" alt="APP" />App
          </p>
        </div>

        <div class="tabs-box">
          <div class="tabs-content">
            <div class="tab active-tab" id="tab-1">
              <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-12 pricing-column">
                  <div class="pricing-block-one">
                    <div class="pricing-table">
                      <figure class="image">
                        <img src="images/icon-2-01.png" alt="" />
                      </figure>
                      <div class="table-header">
                        <h3 class="price">數位閱讀</h3>
                      </div>
                      <div class="table-content">
                        <ul>
                          <li>
                            <img
                              src="images/icons/image041.png"
                              width="25px"
                              alt="logo"
                            /><a
                              href="https://nclibtv.ncl.edu.tw/web/index.php"
                              target="_blank"
                            >
                              國圖到你家 </a
                            ><img src="images/globe.png" alt="" />
                            <img src="images/apps.png" alt="APP" />
                          </li>
                          <li>
                            <img
                              src="images/icons/image042.png"
                              width="25px"
                              alt="logo"
                            /><a
                              href="https://ebook.ncl.edu.tw/webpac/index.jsp"
                              target="_blank"
                              >NCL Reader </a
                            ><img src="images/globe.png" alt="" />
                            <img src="images/apps.png" alt="APP" />
                          </li>
                          <li>
                            <a href="https://www.nlpi.edu.tw/" target="_blank"
                              >國立公共資訊圖書館 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <img
                              src="images/icons/image046.png"
                              width="50px"
                              alt="logo"
                            /><a
                              href="https://children.moc.gov.tw/index"
                              target="_blank"
                              >文化部兒童文化館 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://theme.npm.edu.tw/npmonline/"
                              target="_blank"
                              >線上故宮 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-12 pricing-column">
                  <div class="pricing-block-one">
                    <div class="pricing-table">
                      <figure class="image">
                        <img src="images/icon-5-01.png" alt="" />
                      </figure>
                      <div class="table-header">
                        <h3 class="price">縣市專區</h3>
                      </div>
                      <div class="table-content">
                        <ul>
                          <li>
                            <a href="https://cooc.tp.edu.tw/" target="_blank">
                              酷課雲 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://drlive.kh.edu.tw/drlive/"
                              target="_blank"
                            >
                              達學堂 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 pricing-column">
                  <div class="pricing-block-one">
                    <div class="pricing-table">
                      <figure class="image">
                        <img src="images/icon-5-01.png" alt="" />
                      </figure>
                      <div class="table-header">
                        <h3 class="price">教科書專區</h3>
                      </div>
                      <div class="table-content">
                        <ul>
                          <li>
                            <a
                              href="https://dgs.ltedu.com.tw/web/"
                              target="_blank"
                            >
                              龍騰 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://www.taiyucoo.com.tw/"
                              target="_blank"
                            >
                              泰宇 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 pricing-column">
                  <div class="pricing-block-one">
                    <div class="pricing-table">
                      <figure class="image">
                        <img src="images/icon-5-01.png" alt="" />
                      </figure>
                      <div class="table-header">
                        <h3 class="price">數位學習平臺</h3>
                      </div>
                      <div class="table-content">
                        <ul>
                          <li>
                            <a
                              href="https://adl.edu.tw/HomePage/home/"
                              target="_blank"
                            >
                              教育部因材網 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://www.junyiacademy.org/"
                              target="_blank"
                            >
                              均一 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 pricing-column">
                  <div class="pricing-block-one">
                    <div class="pricing-table">
                      <figure class="image">
                        <img src="images/icon-5-01.png" alt="" />
                      </figure>
                      <div class="table-header">
                        <h3 class="price">教育部專區</h3>
                      </div>
                      <div class="table-content">
                        <ul>
                          <li>
                            <a
                              href="https://exam.tcte.edu.tw/tbt_html/"
                              target="_blank"
                            >
                              國民小學及國民中學學生學習扶助科技化評量 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://isafeevent.moe.edu.tw/"
                              target="_blank"
                            >
                              全民資安素養自我評量 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://compete.law.moj.gov.tw/"
                              target="_blank"
                            >
                              全國法規資料庫競賽活動網站 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://www.greenschool.moe.edu.tw/gs2/"
                              target="_blank"
                            >
                              教育部綠色學校夥伴網路平臺 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://acmoocs.tnua.edu.tw/"
                              target="_blank"
                            >
                              美感素養磨課師線上課程 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="http://oceantag.nmmba.gov.tw/"
                              target="_blank"
                            >
                              國立海洋生物博物館/Ocean Tag學習網 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://www.nmmba.gov.tw/cp.aspx?n=DFE4ED88BCFC8B23&s=C254FFD10CAFF809"
                              target="_blank"
                            >
                              國立海洋生物博物館/行動展示箱 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://mscloud.nmmst.gov.tw/chhtml/index"
                              target="_blank"
                            >
                              國立海洋科技博物館/優遊數位海洋行動學堂 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://market.cloud.edu.tw/"
                              target="_blank"
                            >
                              教育大市集 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://pedia.cloud.edu.tw/"
                              target="_blank"
                            >
                              教育百科 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://disaster.moe.edu.tw/WebMoeInfo/home.aspx"
                              target="_blank"
                            >
                              教育部/防災教育資訊網防災總動員 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a href="https://adl.edu.tw/trees/" target="_blank">
                              守護木林森 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://outdoor.moe.edu.tw/"
                              target="_blank"
                            >
                              教育部戶外教育資源平台 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a href="https://isafe.moe.edu.tw/" target="_blank">
                              教育部全民資安素養網 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a href="https://www.aade.org.tw/" target="_blank">
                              教育部美感與設計課程創新計畫官方網站 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://cirn.moe.edu.tw/WebContent/index.aspx?sid=1107&mid=5855"
                              target="_blank"
                            >
                              教育部國民中小學課程與教學資源整合平臺 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="http://gender.nhes.edu.tw/"
                              target="_blank"
                            >
                              教育部國民及學前教育署高級中等學校性別平等教育資源中心網站 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://enc.moe.edu.tw/EbookLis"
                              target="_blank"
                            >
                              教育部學生事務及特殊教育司/防制學生藥物濫用資源網 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://video.cloud.edu.tw/"
                              target="_blank"
                            >
                              教育媒體影音 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a href="https://cloud.edu.tw/" target="_blank">
                              教育雲 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://www.youtube.com/watch?v=otAHmDEocI0"
                              target="_blank"
                            >
                              經濟部水利署/YOUTUBE </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://fhy.wra.gov.tw/fhyv2/service/knowledge"
                              target="_blank"
                            >
                              經濟部水利署/防災服務資訊網 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://artistic.finearts.ntnu.edu.tw/"
                              target="_blank"
                            >
                              藝術教育推動資源中心-教育部藝術才能專長領域輔導群109教學示例 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://youtube.com/playlist?list=PLTIqZR9MAbCD8QWGO5YCW3BciAwYeVdY1"
                              target="_blank"
                            >
                              「兒童安全通過路口」數位課程 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://youtube.com/playlist?list=PLTIqZR9MAbCBxzcJoxLmnpqi1kuIJHhxQ"
                              target="_blank"
                            >
                              安全騎乘自行車數位課程 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://www.youtube.com/watch?v=Nhcmc3W5P6M"
                              target="_blank"
                            >
                              【靖娟x遠通電收】馬路這樣過，安全READY GO！ </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://www.youtube.com/watch?v=0lOTJpTRbbY&t=7s"
                              target="_blank"
                            >
                              【靖娟x遠通電收】 騎乘注意GO 安全一定夠！ </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://www.youtube.com/watch?v=5QcnDFTBfaM&t=325s"
                              target="_blank"
                            >
                              【公車友善心運動】萱萱的冒險日記 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://www1.inservice.edu.tw/"
                              target="_blank"
                            >
                              全國教師在職進修網 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://www.nmns.edu.tw/learn/online-museum/catalog/"
                              target="_blank"
                            >
                              國立自然科學博物館/國立自然科學博物館線上博物館 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a href="https://www.ntm.gov.tw/" target="_blank">
                              國立臺灣博物館/國立臺灣博物館官網 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a href="https://ups.moe.edu.tw/" target="_blank">
                              教師e學院 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://mlearn.moe.gov.tw/"
                              target="_blank"
                            >
                              媒體素養教育資源網 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://energy.mt.ntnu.edu.tw/"
                              target="_blank"
                            >
                              經濟部能源局/能源教育資訊網 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a href="https://reurl.cc/GoY6RW" target="_blank">
                              臺灣教育研究資訊網 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- -section new-->
    <!-- <section class="pricing-section centred">
      <div
        class="image-layer"
        style="background-image: url(images/icons/testimonial-bg.png)"
      ></div>
      <div class="container">
        <div class="sec-title center">
          <h2>學習專區</h2>
          <p>
            <img src="images/globe.png" alt="WEB" />網站
            <img src="images/apps.png" alt="APP" />App
          </p>
        </div>
        <div class="tabs-box">
          <div class="tabs-content">
            <div class="tab active-tab" id="tab-1">
              <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-12 pricing-column">
                  <div class="pricing-block-one">
                    <div class="pricing-table">
                      <figure class="image">
                        <img src="images/icon-5-01.png" alt="" />
                      </figure>
                      <div class="table-header">
                        <h3 class="price">縣市專區</h3>
                      </div>
                      <div class="table-content">
                        <ul>
                          <li>
                            <a href="https://cooc.tp.edu.tw/" target="_blank">
                              酷課雲 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://drlive.kh.edu.tw/drlive/"
                              target="_blank"
                            >
                              達學堂 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 pricing-column">
                  <div class="pricing-block-one">
                    <div class="pricing-table">
                      <figure class="image">
                        <img src="images/icon-5-01.png" alt="" />
                      </figure>
                      <div class="table-header">
                        <h3 class="price">教科書專區</h3>
                      </div>
                      <div class="table-content">
                        <ul>
                          <li>
                            <a
                              href="https://dgs.ltedu.com.tw/web/"
                              target="_blank"
                            >
                              龍騰 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://www.taiyucoo.com.tw/"
                              target="_blank"
                            >
                              泰宇 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 pricing-column">
                  <div class="pricing-block-one">
                    <div class="pricing-table">
                      <figure class="image">
                        <img src="images/icon-5-01.png" alt="" />
                      </figure>
                      <div class="table-header">
                        <h3 class="price">數位學習平臺</h3>
                      </div>
                      <div class="table-content">
                        <ul>
                          <li>
                            <a
                              href="https://adl.edu.tw/HomePage/home/"
                              target="_blank"
                            >
                              教育部因材網 </a
                            ><img src="images/globe.png" alt="" />
                          </li>

                          <li>
                            <a
                              href="https://www.junyiacademy.org/"
                              target="_blank"
                            >
                              均一 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 pricing-column">
                  <div class="pricing-block-one">
                    <div class="pricing-table">
                      <figure class="image">
                        <img src="images/icon-2-01.png" alt="" />
                      </figure>
                      <div class="table-header">
                        <h3 class="price">數位閱讀</h3>
                      </div>
                      <div class="table-content">
                        <ul>
                          <li>
                            <img
                              src="images/icons/image041.png"
                              width="25px"
                              alt="logo"
                            /><a
                              href="https://nclibtv.ncl.edu.tw/web/index.php"
                              target="_blank"
                            >
                              國圖到你家 </a
                            ><img src="images/globe.png" alt="" />
                            <img src="images/apps.png" alt="APP" />
                          </li>
                          <li>
                            <img
                              src="images/icons/image042.png"
                              width="25px"
                              alt="logo"
                            /><a
                              href="https://ebook.ncl.edu.tw/webpac/index.jsp"
                              target="_blank"
                              >NCL Reader </a
                            ><img src="images/globe.png" alt="" />
                            <img src="images/apps.png" alt="APP" />
                          </li>
                          <li>
                            <a href="https://www.nlpi.edu.tw/" target="_blank"
                              >國立公共資訊圖書館 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <img
                              src="images/icons/image046.png"
                              width="50px"
                              alt="logo"
                            /><a
                              href="https://children.moc.gov.tw/index"
                              target="_blank"
                              >文化部兒童文化館 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                          <li>
                            <a
                              href="https://theme.npm.edu.tw/npmonline/"
                              target="_blank"
                              >線上故宮 </a
                            ><img src="images/globe.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>



<script>
export default {
  name: "Lists",
};
</script>

<style>
</style>
    