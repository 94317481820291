<template>
  <div class="_my_owerlay" @click="closeEmittedFolder">
    <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  <router-view/> -->

    <!-- preloader -->
    <div class="preloader"></div>
    <!-- preloader -->

    <!-- main header -->
    <header class="main-header home-1">
      <div class="outer-container">
        <div class="container">
          <div class="main-box clearfix">
            <div class="logo-box pull-left">
              <figure class="logo">
                <a href="index-2.html"><img src="images/logo.png" alt="" /></a>
              </figure>
            </div>
            <div class="menu-area pull-right">
              <!--Mobile Navigation Toggler-->
              <div class="mobile-nav-toggler">
                <i class="icon-bar"></i>
                <i class="icon-bar"></i>
                <i class="icon-bar"></i>
              </div>
              <nav class="main-menu navbar-expand-md navbar-light"></nav>
            </div>
          </div>
        </div>
      </div>

      <!--sticky Header-->
      <div class="sticky-header">
        <div class="container clearfix">
          <figure class="logo-box">
            <a href="index-2.html"
              ><img src="images/small-logo.png" alt=""
            /></a>
          </figure>
          <div class="menu-area">
            <nav class="main-menu clearfix">
              <!--Keep This Empty / Menu will come through Javascript-->
            </nav>
          </div>
        </div>
      </div>
    </header>
    <!-- main-header end -->

    <!-- banner-section -->
    <section class="banner-section">
      <div
        class="bg-layer"
        style="background-image: url(images/banner-bg.png)"
      ></div>
      <div
        class="pattern-bg"
        style="background-image: url(images/icons/vactor-1.png)"
      ></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12 content-column">
            <div class="content-box">
              <figure
                class="image image-1 wow fadeInUp"
                data-wow-delay="900ms"
                data-wow-duration="1500ms"
              >
                <img src="images/eduicon02.png" alt="" />
              </figure>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 image-column">
            <div class="image-box float-bob-y clearfix">
              <figure
                class="image image-1 wow fadeInUp"
                data-wow-delay="900ms"
                data-wow-duration="1500ms"
              >
                <img src="images/eduicon03.png" alt="" />
              </figure>
              <figure
                class="image image-2 wow fadeInUp"
                data-wow-delay="1500ms"
                data-wow-duration="1500ms"
              >
                <img src="images/eduicon01.png" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- banner-section end -->

    <div class="switch-components-buttons">
      <TogglePage @swich-page="swichComponent" />
      <!-- <button @click="swichComponent('Lists')">1</button
      ><button @click="swichComponent('Apps')">2</button> -->
    </div>
    <!-- section -->

    <Transition name="fade" mode="out-in">
      <component
        :is="CurrentComponent"
        @emit-folder="emitOpenFolder"
      ></component>
    </Transition>

    <!-- <Lists /> -->
    <!-- section end -->

    <!-- main-footer -->
    <footer class="main-footer">
      <div
        class="image-layer"
        style="background-image: url(images/footer-bg.png)"
      ></div>
      <div class="container">
        <div class="footer-top">
          <div class="widget-section">
            <div class="row" style="display: block">
              <div style="float: left">
                © 2022
                <a href="https://gtrainers.org/" target="_blank"
                  >gtrainers.org</a
                >
              </div>
              <div style="float: right">
                Powered By:
                <a href="https://gtrainers.org/" target="_blank"
                  >GTrainers.org</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- main-footer end -->

    <!--Scroll to top-->
    <button class="scroll-top scroll-to-target" data-target="html">
      <span class="fa fa-arrow-up"></span>
    </button>
    <!-- <button type="button" id="fsbtn" style="opacity: 0"></button> -->
  </div>
</template>
<script>
import Apps from "./views/Apps.vue";
import Lists from "./views/Lists.vue";
import TogglePage from "./components/ui/TogglePage.vue";
import { fullScreen } from "./utils/fullscreen.js";
export default {
  components: { Apps, Lists, TogglePage },

  data() {
    return {
      currentElement: null,
      CurrentComponent: Apps,
    };
  },

  mounted() {
    // let btn =
    // console.log(btn);
    // btn.dispatchEvent(new Event("click"));
    // let elem = document.documentElement;
    // elem
    //   .requestFullscreen({ navigationUI: "show" })
    //   .then(() => {})
    //   .catch((err) => {
    //     alert(
    //       `An error occurred while trying to switch into fullscreen mode: ${err.message} (${err.name})`
    //     );
    //   });
    // let btn = document.getElementById("fsbtn");
    // btn.addEventListener("click", async function () {
    //   // alert("Mouse Clicked");
    //   let html = document.getElementById("fullsrcn");
    //   await fullScreen(html);
    // });
    // let clickEvent = new Event("click");
    // btn.dispatchEvent(clickEvent);
  },

  methods: {
    async setFullScrn() {
      let html = document.getElementById("fullsrcn");
      await fullScreen(html);
    },
    swichComponent(data) {
      if (data) {
        this.CurrentComponent = "Lists";
      } else {
        this.CurrentComponent = "Apps";
      }
      // this.CurrentComponent = Component;
    },
    emitOpenFolder(e, appId) {
      // console.log(e.currentTarget);
      if (
        this.currentElement !== null &&
        this.currentElement.classList.contains("open")
      ) {
        // console.log("nen");
        // this.currentElement.classList.remove("open");
        // this.$store.commit("setAppsOpen", false);
        // this.$store.getters.isReady();
        // this.$store.commit("setAppId", false);
      } else {
        // this.currentElement.classList.remove("open");
        e.currentTarget.classList.add("open");
        this.currentElement = e.currentTarget;
        this.$store.commit("setAppsOpen", true);
        // this.$store.commit("setIsReady", false);
        this.$store.commit("setAppId", appId);
        // console.log("запуст гетера");
        this.$store.getters.isReady();
      }
    },
    closeEmittedFolder(e) {
      if (!this.currentElement) {
        return;
      }
      if (e.target.dataset.id === this.$store.state.appId) return;
      // console.log(e.target.dataset.id === this.$store.state.appId);
      // console.log("e cur el", this.currentElement);
      if (
        e.target.classList.contains("carousel__pagination") ||
        e.target.classList.contains("carousel__pagination-item") ||
        e.target.classList.contains("carousel__pagination-button") ||
        e.target.classList.contains("outfolder-title") ||
        e.target.classList.contains("apps-list") ||
        e.target.classList.contains("carousel__slide") ||
        (e.target.classList.contains("item-contaiter") &&
          e.target.parentNode.classList.contains("open"))
      ) {
        if (e.target.dataset.id === this.$store.state.appId) {
          console.log(this.$store.state.appId);
          // this.currentElement.classList.remove("open");
          // this.currentElement = null;
          // this.$store.commit("setAppsOpen", false);
          // this.$store.commit("setIsReady", false);
          // this.$store.commit("setAppId", false);
          return;
        }
        // console.log("bef clise", e.target);
        return;
      }
      // console.log("on close");
      this.currentElement.classList.remove("open");
      this.currentElement = null;
      this.$store.commit("setAppsOpen", false);
      this.$store.commit("setIsReady", false);
      this.$store.commit("setAppId", false);
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
/* .carousel {
  display: flex;
  flex-direction: column;
 
  height: 100%;
  justify-content: space-between;
} */
</style>
