import { createStore } from "vuex";

export default createStore({
  state: () => ({
    appsOpen: false,
    isReady: false,
    appId: false,
  }),
  getters: {
    isReady: (state) => () => {
      if (state.appsOpen) {
        state.isReady = false;
        setTimeout(() => {
          state.isReady = true;
          return true;
        }, 200);
      }
      // return false;
    },
  },
  mutations: {
    setAppsOpen: (state, bool) => (state.appsOpen = bool),
    setIsReady: (state, bool) => (state.isReady = bool),
    setAppId: (state, int) => (state.appId = int),
  },
  actions: {},
  modules: {},
});
