<template>
  <div id="container" @click="toggle">
    <div class="inner-container">
      <div class="toggle">
        <p>學習資源網站</p>
      </div>
      <div class="toggle">
        <p>Android App</p>
      </div>
    </div>
    <div ref="toggleContainer" class="inner-container" id="toggle-container">
      <div class="toggle">
        <p>學習資源網站</p>
      </div>
      <div class="toggle">
        <p>Android App</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TogglePage",
  data() {
    return {
      toggleNumber: false,
    };
  },

  methods: {
    toggle(e) {
      this.toggleNumber = !this.toggleNumber;
      if (this.toggleNumber) {
        this.$refs.toggleContainer.style.clipPath = "inset(0 0 0 50%)";
        this.$refs.toggleContainer.style.backgroundColor = "dodgerblue";
      } else {
        this.$refs.toggleContainer.style.clipPath = "inset(0 50% 0 0)";
        this.$refs.toggleContainer.style.backgroundColor = "dodgerblue";
      }
      this.$emit("swich-page", this.toggleNumber);
      //   console.log(this.toggleNumber);
    },
  },
};

// var toggle = document.getElementById("container");
// var toggleContainer = document.getElementById("toggle-container");
// var toggleNumber;

// toggle.addEventListener("click", function () {
//   toggleNumber = !toggleNumber;
//   if (toggleNumber) {
//     toggleContainer.style.clipPath = "inset(0 0 0 50%)";
//     toggleContainer.style.backgroundColor = "#D74046";
//   } else {
//     toggleContainer.style.clipPath = "inset(0 50% 0 0)";
//     toggleContainer.style.backgroundColor = "dodgerblue";
//   }
//   console.log(toggleNumber);
// });
</script>

<style scoped>
#container {
  width: 300px;
  height: 36px;
  margin: auto;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
}

.inner-container {
  position: absolute;
  left: 0;
  top: 0;
  width: inherit;
  height: inherit;
  text-transform: uppercase;
  font-size: 0.6em;
  letter-spacing: 0.2em;
}

.inner-container:first-child {
  background: #e9e9e9;
  color: #a9a9a9;
}

.inner-container:nth-child(2) {
  background: dodgerblue;
  color: white;
  clip-path: inset(0 50% 0 0);
  transition: 0.3s cubic-bezier(0, 0, 0, 1);
}

.toggle {
  width: 50%;
  position: absolute;
  height: inherit;
  display: flex;
  box-sizing: border-box;
}

.toggle p {
  margin: auto;
  font-size: 13px;
}

.toggle:nth-child(1) {
  right: 0;
}
</style>